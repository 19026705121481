
import Vue from 'vue'

const locales = {keys(){return []}}
global['____23FB495____'] = true;
delete global['____23FB495____'];
global.__uniConfig = {"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"量见云课堂","navigationBarBackgroundColor":"#FFFFFF","backgroundColor":"#f8f8f8","onReachBottomDistance":80},"tabBar":{"color":"#999","selectedColor":"#007aff","borderStyle":"black","backgroundColor":"","iconWidth":"","fontSize":"","height":"","list":[{"pagePath":"pages/app2022/index/index","iconPath":"","selectedIconPath":"","text":"","redDot":false,"badge":""},{"pagePath":"pages/app2022/discovery/Discovery","iconPath":"","selectedIconPath":"","text":"","redDot":false,"badge":""},{"pagePath":"pages/app2022/study/Study","iconPath":"","selectedIconPath":"","text":"","redDot":false,"badge":""},{"pagePath":"pages/user/userCenter","iconPath":"","selectedIconPath":"","text":"","redDot":false,"badge":""}]},"condition":{"current":0,"list":[{"name":"","path":"pages/app2022/index/index","query":""}]}};
global.__uniConfig.compilerVersion = '4.08';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '__UNI__23FB495';
global.__uniConfig.appName = '量见·云课堂';
global.__uniConfig.appVersion = '1.0.0';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {"maps":{}};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-app2022-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/index/index"+'.vue')), 'pages-app2022-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-leaderboardDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/leaderboardDetail"+'.vue')), 'pages-app2022-leaderboardDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-PendingTask', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/PendingTask"+'.vue')), 'pages-app2022-PendingTask'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-StudyHeatBoard', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/StudyHeatBoard"+'.vue')), 'pages-app2022-study-StudyHeatBoard'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-CreditDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/CreditDetail"+'.vue')), 'pages-app2022-study-CreditDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-CreditDetailFilter', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/CreditDetailFilter"+'.vue')), 'pages-app2022-study-CreditDetailFilter'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CompanyNews', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CompanyNews"+'.vue')), 'pages-app2022-CompanyNews'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CompanyNewsDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CompanyNewsDetail"+'.vue')), 'pages-app2022-CompanyNewsDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-Discovery', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/Discovery"+'.vue')), 'pages-app2022-discovery-Discovery'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-module-content', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/module-content"+'.vue')), 'pages-app2022-discovery-module-content'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-LimitDiscounts', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/LimitDiscounts"+'.vue')), 'pages-app2022-LimitDiscounts'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-Study', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/Study"+'.vue')), 'pages-app2022-study-Study'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CampAttended', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CampAttended"+'.vue')), 'pages-app2022-CampAttended'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-StudyReport', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/StudyReport"+'.vue')), 'pages-app2022-study-StudyReport'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-StudyReportDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/StudyReportDetail"+'.vue')), 'pages-app2022-study-StudyReportDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-StudyRecently', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/StudyRecently"+'.vue')), 'pages-app2022-study-StudyRecently'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CampNewest', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CampNewest"+'.vue')), 'pages-app2022-CampNewest'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseNewest', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseNewest"+'.vue')), 'pages-app2022-CourseNewest'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-WeekCampTop10', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/WeekCampTop10"+'.vue')), 'pages-app2022-WeekCampTop10'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-filterCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/filterCourse"+'.vue')), 'pages-app2022-filterCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseStudy', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseStudy"+'.vue')), 'pages-app2022-CourseStudy'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CompanyNewsExLink', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CompanyNewsExLink"+'.vue')), 'pages-app2022-CompanyNewsExLink'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-WeekCourseTop10', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/WeekCourseTop10"+'.vue')), 'pages-app2022-WeekCourseTop10'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-RecommendTopic', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/RecommendTopic"+'.vue')), 'pages-app2022-RecommendTopic'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-celebrityColumn', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/celebrityColumn"+'.vue')), 'pages-knowledgeSpace-celebrityColumn'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-teacherDetails', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/teacherDetails"+'.vue')), 'pages-knowledgeSpace-teacherDetails'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-realBattlefield', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/realBattlefield"+'.vue')), 'pages-knowledgeSpace-realBattlefield'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-professionalCollege', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/professionalCollege"+'.vue')), 'pages-knowledgeSpace-professionalCollege'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/search"+'.vue')), 'pages-knowledgeSpace-search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userCenter', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userCenter"+'.vue')), 'pages-user-userCenter'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-studyplan-studyplan', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/studyplan/studyplan"+'.vue')), 'pages-studyplan-studyplan'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-studyplan-studyplandetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/studyplan/studyplandetail"+'.vue')), 'pages-studyplan-studyplandetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-studyplan-courseProgress', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/studyplan/courseProgress"+'.vue')), 'pages-studyplan-courseProgress'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-enterpriseCourse-enterpriseCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/enterpriseCourse/enterpriseCourse"+'.vue')), 'pages-enterpriseCourse-enterpriseCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-enterpriseCourse-recommendCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/enterpriseCourse/recommendCourse"+'.vue')), 'pages-enterpriseCourse-recommendCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-companyLiveVideo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/companyLiveVideo"+'.vue')), 'pages-index-companyLiveVideo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-testPaper-testPaper', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/testPaper/testPaper"+'.vue')), 'pages-testPaper-testPaper'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-testPaper-testResult', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/testPaper/testResult"+'.vue')), 'pages-testPaper-testResult'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-testPaper-questionNaire', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/testPaper/questionNaire"+'.vue')), 'pages-testPaper-questionNaire'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-liveVideo-liveVideo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/liveVideo/liveVideo"+'.vue')), 'pages-liveVideo-liveVideo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-enterpriseCourse-courseAudio', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/enterpriseCourse/courseAudio"+'.vue')), 'pages-enterpriseCourse-courseAudio'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-enterpriseCourse-courseVideo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/enterpriseCourse/courseVideo"+'.vue')), 'pages-enterpriseCourse-courseVideo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userOrder', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userOrder"+'.vue')), 'pages-user-userOrder'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userFavorite', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userFavorite"+'.vue')), 'pages-user-userFavorite'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userFeedback', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userFeedback"+'.vue')), 'pages-user-userFeedback'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userCertificate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userCertificate"+'.vue')), 'pages-user-userCertificate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userCertificateDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userCertificateDetail"+'.vue')), 'pages-user-userCertificateDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userCertificateGoShare', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userCertificateGoShare"+'.vue')), 'pages-user-userCertificateGoShare'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userCertificateShare', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userCertificateShare"+'.vue')), 'pages-user-userCertificateShare'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userFocus', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userFocus"+'.vue')), 'pages-user-userFocus'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userStudyRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userStudyRecord"+'.vue')), 'pages-user-userStudyRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userFile', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userFile"+'.vue')), 'pages-user-userFile'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-liveColumn', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/liveColumn"+'.vue')), 'pages-knowledgeSpace-liveColumn'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-embaDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/embaDetail"+'.vue')), 'pages-knowledgeSpace-embaDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-teacherHall', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/teacherHall"+'.vue')), 'pages-knowledgeSpace-teacherHall'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-teacherHallDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/teacherHallDetail"+'.vue')), 'pages-knowledgeSpace-teacherHallDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/login"+'.vue')), 'pages-index-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-umsCu', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/umsCu"+'.vue')), 'pages-index-umsCu'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-examEvaluation-trainList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/examEvaluation/trainList"+'.vue')), 'pages-examEvaluation-trainList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-examEvaluation-trainDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/examEvaluation/trainDetail"+'.vue')), 'pages-examEvaluation-trainDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-examEvaluation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/examEvaluation"+'.vue')), 'pages-exam-examEvaluation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-rank', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/rank"+'.vue')), 'pages-exam-rank'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-examDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/examDetail"+'.vue')), 'pages-exam-examDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-examPaper', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/examPaper"+'.vue')), 'pages-exam-examPaper'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-examReview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/examReview"+'.vue')), 'pages-exam-examReview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-examRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/examRecord"+'.vue')), 'pages-exam-examRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-external', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/external"+'.vue')), 'pages-exam-external'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-trainingCamp', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/trainingCamp"+'.vue')), 'pages-user-trainingCamp'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-collectionDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/collectionDetail"+'.vue')), 'pages-knowledgeSpace-collectionDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-vocationalSkills', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/vocationalSkills"+'.vue')), 'pages-knowledgeSpace-vocationalSkills'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-vocationalSkillsII', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/vocationalSkillsII"+'.vue')), 'pages-knowledgeSpace-vocationalSkillsII'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-changeCompany', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/changeCompany"+'.vue')), 'pages-user-changeCompany'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-vipHistory', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/vipHistory"+'.vue')), 'pages-user-vipHistory'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-trainingCampDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/trainingCampDetail"+'.vue')), 'pages-user-trainingCampDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxHomePage', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxHomePage"+'.vue')), 'pages-zzx-zzxHomePage'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userStudyRecordCAPM', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userStudyRecordCAPM"+'.vue')), 'pages-user-userStudyRecordCAPM'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxOrderConfirm', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxOrderConfirm"+'.vue')), 'pages-zzx-zzxOrderConfirm'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxChooseAddress', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxChooseAddress"+'.vue')), 'pages-zzx-zzxChooseAddress'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxChooseCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxChooseCourse"+'.vue')), 'pages-zzx-zzxChooseCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxChooseCourseConfirm', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxChooseCourseConfirm"+'.vue')), 'pages-zzx-zzxChooseCourseConfirm'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-zzxStudyCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/zzxStudyCourse"+'.vue')), 'pages-zzx-zzxStudyCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-zzx-annualReview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/zzx/annualReview"+'.vue')), 'pages-zzx-annualReview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-filterCategoryDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/filterCategoryDetail"+'.vue')), 'pages-knowledgeSpace-filterCategoryDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-knowledgeSpace-zhuanti', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/knowledgeSpace/zhuanti"+'.vue')), 'pages-knowledgeSpace-zhuanti'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-qychat-specialTopicDetails', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/qychat/specialTopicDetails"+'.vue')), 'pages-qychat-specialTopicDetails'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-qychat-specialTopic', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/qychat/specialTopic"+'.vue')), 'pages-qychat-specialTopic'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-live-liveCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/live/liveCourse"+'.vue')), 'pages-live-liveCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-live-live', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/live/live"+'.vue')), 'pages-live-live'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-selectHobby', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/selectHobby"+'.vue')), 'pages-index-selectHobby'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lebo-lebo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lebo/lebo"+'.vue')), 'pages-lebo-lebo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-questionnaire-questionnaireList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/questionnaire/questionnaireList"+'.vue')), 'pages-questionnaire-questionnaireList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-questionnaire-questionnaireDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/questionnaire/questionnaireDetail"+'.vue')), 'pages-questionnaire-questionnaireDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCamp-trainCampDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCamp/trainCampDetail"+'.vue')), 'pages-trainCamp-trainCampDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCamp-trainCampVideoPlayer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCamp/trainCampVideoPlayer"+'.vue')), 'pages-trainCamp-trainCampVideoPlayer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-studyConsultant', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/studyConsultant"+'.vue')), 'pages-user-studyConsultant'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainOnline-onlineList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainOnline/onlineList"+'.vue')), 'pages-trainOnline-onlineList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-resources-companyCourse', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/resources/companyCourse"+'.vue')), 'pages-resources-companyCourse'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-vipIntroduce', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/vipIntroduce"+'.vue')), 'pages-user-vipIntroduce'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-vipClass', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/vipClass"+'.vue')), 'pages-user-vipClass'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-protocol-protocol', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/protocol/protocol"+'.vue')), 'pages-protocol-protocol'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-media-play-video', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/media-play/video"+'.vue')), 'pages-media-play-video'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-media-play-knowledge', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/media-play/knowledge"+'.vue')), 'pages-media-play-knowledge'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-courseDetail-courseDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/courseDetail/courseDetail"+'.vue')), 'pages-courseDetail-courseDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-userAccount', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/userAccount"+'.vue')), 'pages-user-userAccount'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-changeAccount', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/changeAccount"+'.vue')), 'pages-user-changeAccount'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-changePass', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/changePass"+'.vue')), 'pages-user-changePass'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-changeEmail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/changeEmail"+'.vue')), 'pages-user-changeEmail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-faceVerify', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/faceVerify"+'.vue')), 'pages-user-faceVerify'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCamp-trainCampWord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCamp/trainCampWord"+'.vue')), 'pages-trainCamp-trainCampWord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-planTest-testInfo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/planTest/testInfo"+'.vue')), 'pages-planTest-testInfo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainOnline-spacex', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainOnline/spacex"+'.vue')), 'pages-trainOnline-spacex'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainOnline-spacexDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainOnline/spacexDetail"+'.vue')), 'pages-trainOnline-spacexDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainOnline-SpaceChart', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainOnline/SpaceChart"+'.vue')), 'pages-trainOnline-SpaceChart'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainOnline-SpaceHome', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainOnline/SpaceHome"+'.vue')), 'pages-trainOnline-SpaceHome'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-newUserCertificate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/newUserCertificate"+'.vue')), 'pages-user-newUserCertificate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-newUserCertificateDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/newUserCertificateDetail"+'.vue')), 'pages-user-newUserCertificateDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-TrainCampPeriod', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/TrainCampPeriod"+'.vue')), 'pages-trainCampNew-TrainCampPeriod'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-PeriodPreview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/PeriodPreview"+'.vue')), 'pages-trainCampNew-PeriodPreview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-PeriodDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/PeriodDetail"+'.vue')), 'pages-trainCampNew-PeriodDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-IntegralDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/IntegralDetail"+'.vue')), 'pages-trainCampNew-IntegralDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-ScheduleDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/ScheduleDetail"+'.vue')), 'pages-trainCampNew-ScheduleDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-GrandDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/GrandDetail"+'.vue')), 'pages-trainCampNew-GrandDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-FeatureCase', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/FeatureCase"+'.vue')), 'pages-trainCampNew-FeatureCase'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-KnowledgeCard', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/KnowledgeCard"+'.vue')), 'pages-trainCampNew-KnowledgeCard'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-ChosenHomework', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/ChosenHomework"+'.vue')), 'pages-trainCampNew-ChosenHomework'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-GraphicsFiles', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/GraphicsFiles"+'.vue')), 'pages-trainCampNew-level-GraphicsFiles'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-PreviewDocument', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/PreviewDocument"+'.vue')), 'pages-trainCampNew-level-Operation-PreviewDocument'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-test-Result', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/test/Result"+'.vue')), 'pages-trainCampNew-level-test-Result'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-test-StartTest', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/test/StartTest"+'.vue')), 'pages-trainCampNew-level-test-StartTest'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-test-TestRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/test/TestRecord"+'.vue')), 'pages-trainCampNew-level-test-TestRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-test-TestPaper', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/test/TestPaper"+'.vue')), 'pages-trainCampNew-level-test-TestPaper'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-test-TestReview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/test/TestReview"+'.vue')), 'pages-trainCampNew-level-test-TestReview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-Operation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/Operation"+'.vue')), 'pages-trainCampNew-level-Operation-Operation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-OperationAll', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/OperationAll"+'.vue')), 'pages-trainCampNew-level-Operation-OperationAll'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-OperationDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/OperationDetail"+'.vue')), 'pages-trainCampNew-level-Operation-OperationDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-AllReply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/AllReply"+'.vue')), 'pages-trainCampNew-level-Operation-AllReply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Operation-OperationSubmit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Operation/OperationSubmit"+'.vue')), 'pages-trainCampNew-level-Operation-OperationSubmit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainCampNew-level-Questionnaire', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainCampNew/level/Questionnaire"+'.vue')), 'pages-trainCampNew-level-Questionnaire'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainOmoList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainOmoList"+'.vue')), 'pages-trainProgram-TrainOmoList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-sign', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/sign"+'.vue')), 'pages-trainProgram-sign'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainOmoDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainOmoDetail"+'.vue')), 'pages-trainProgram-TrainOmoDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainOfflineClass', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainOfflineClass"+'.vue')), 'pages-trainProgram-TrainOfflineClass'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainOfflineAssess', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainOfflineAssess"+'.vue')), 'pages-trainProgram-TrainOfflineAssess'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainCheckRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainCheckRecord"+'.vue')), 'pages-trainProgram-TrainCheckRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-TrainCheckIn', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/TrainCheckIn"+'.vue')), 'pages-trainProgram-TrainCheckIn'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-homework-Homework', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/homework/Homework"+'.vue')), 'pages-trainProgram-homework-Homework'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-homework-HomeworkDemand', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/homework/HomeworkDemand"+'.vue')), 'pages-trainProgram-homework-HomeworkDemand'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-homework-HomeworkDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/homework/HomeworkDetail"+'.vue')), 'pages-trainProgram-homework-HomeworkDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-homework-HomeworkSubmit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/homework/HomeworkSubmit"+'.vue')), 'pages-trainProgram-homework-HomeworkSubmit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-homework-AllReply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/homework/AllReply"+'.vue')), 'pages-trainProgram-homework-AllReply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-search-Search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/search/Search"+'.vue')), 'pages-search-Search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-mall', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/mall"+'.vue')), 'pages-integralMall-mall'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-AllMedia-Index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/AllMedia/Index"+'.vue')), 'pages-AllMedia-Index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-AllMedia-Practical', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/AllMedia/Practical"+'.vue')), 'pages-AllMedia-Practical'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-AllMedia-Certificate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/AllMedia/Certificate"+'.vue')), 'pages-AllMedia-Certificate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-CheckRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/CheckRecord"+'.vue')), 'pages-app2022-study-CheckRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-CheckRank', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/CheckRank"+'.vue')), 'pages-app2022-study-CheckRank'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-myKnowledge-List', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/myKnowledge/List"+'.vue')), 'pages-myKnowledge-List'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-myKnowledge-UploadKnowledge', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/myKnowledge/UploadKnowledge"+'.vue')), 'pages-myKnowledge-UploadKnowledge'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-myKnowledge-ChooseCover', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/myKnowledge/ChooseCover"+'.vue')), 'pages-myKnowledge-ChooseCover'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-myKnowledge-Comments', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/myKnowledge/Comments"+'.vue')), 'pages-myKnowledge-Comments'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-myKnowledge-AllCommentsReply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/myKnowledge/AllCommentsReply"+'.vue')), 'pages-myKnowledge-AllCommentsReply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-MallCenter', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/MallCenter"+'.vue')), 'pages-integralMall-MallCenter'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-Rules', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/Rules"+'.vue')), 'pages-integralMall-Rules'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-Records', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/Records"+'.vue')), 'pages-integralMall-Records'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-Exchange', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/Exchange"+'.vue')), 'pages-integralMall-Exchange'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-Order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/Order"+'.vue')), 'pages-integralMall-Order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-OrderDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/OrderDetail"+'.vue')), 'pages-integralMall-OrderDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-integralMall-CommodityDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/integralMall/CommodityDetail"+'.vue')), 'pages-integralMall-CommodityDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-autoLogin', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/autoLogin"+'.vue')), 'pages-index-autoLogin'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-cloudCourse-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/cloudCourse/index"+'.vue')), 'pages-cloudCourse-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-examEvaluation-offlineTrainCheck', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/examEvaluation/offlineTrainCheck"+'.vue')), 'pages-examEvaluation-offlineTrainCheck'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-payCenter-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/payCenter/index"+'.vue')), 'pages-payCenter-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-miniMBA-intro', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/miniMBA/intro"+'.vue')), 'pages-miniMBA-intro'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-miniMBA-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/miniMBA/detail"+'.vue')), 'pages-miniMBA-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-ecologyLogin-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/ecologyLogin/index"+'.vue')), 'pages-ecologyLogin-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-talent-talent', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/talent/talent"+'.vue')), 'pages-talent-talent'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-talent-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/talent/detail"+'.vue')), 'pages-talent-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-study-StudyRules', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/study/StudyRules"+'.vue')), 'pages-app2022-study-StudyRules'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-resetPassword', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/index/resetPassword"+'.vue')), 'pages-index-resetPassword'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/list"+'.vue')), 'pages-arena-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-record', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/record"+'.vue')), 'pages-arena-record'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-rank', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/rank"+'.vue')), 'pages-arena-rank'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-level', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/level"+'.vue')), 'pages-arena-level'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-answer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/answer"+'.vue')), 'pages-arena-answer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-pk', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/pk"+'.vue')), 'pages-arena-pk'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-review', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/review"+'.vue')), 'pages-arena-review'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-arena-answerPK', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/arena/answerPK"+'.vue')), 'pages-arena-answerPK'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-examEvaluation-offlineFiles', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/examEvaluation/offlineFiles"+'.vue')), 'pages-examEvaluation-offlineFiles'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-practiceRecord', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/practiceRecord"+'.vue')), 'pages-exam-practiceRecord'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-practicePaper', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/practicePaper"+'.vue')), 'pages-exam-practicePaper'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-exam-practiceReview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/exam/practiceReview"+'.vue')), 'pages-exam-practiceReview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/index"+'.vue')), 'pages-microCourse-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-graphic', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/graphic"+'.vue')), 'pages-microCourse-graphic'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-graphicDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/graphicDetail"+'.vue')), 'pages-microCourse-graphicDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-video', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/video"+'.vue')), 'pages-microCourse-video'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-allReply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/allReply"+'.vue')), 'pages-microCourse-allReply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-create-create', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/create/create"+'.vue')), 'pages-microCourse-create-create'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-create-baseInfo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/create/baseInfo"+'.vue')), 'pages-microCourse-create-baseInfo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-create-systemCover', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/create/systemCover"+'.vue')), 'pages-microCourse-create-systemCover'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-create-contentAdd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/create/contentAdd"+'.vue')), 'pages-microCourse-create-contentAdd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-microCourse-create-videoAdd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/microCourse/create/videoAdd"+'.vue')), 'pages-microCourse-create-videoAdd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-cacheClean', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/cacheClean"+'.vue')), 'pages-cacheClean'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-AiChat', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/AiChat"+'.vue')), 'pages-app2022-AiChat'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-SJTU-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/SJTU/index"+'.vue')), 'pages-SJTU-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-SJTU-board', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/SJTU/board"+'.vue')), 'pages-SJTU-board'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-topic-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/topic-detail"+'.vue')), 'pages-app2022-discovery-topic-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-select-hobby', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/select-hobby"+'.vue')), 'pages-app2022-discovery-select-hobby'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-content-more', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/content-more"+'.vue')), 'pages-app2022-discovery-content-more'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-module-category', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/module-category"+'.vue')), 'pages-app2022-discovery-module-category'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-topic-equity-card', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/topic-equity-card"+'.vue')), 'pages-app2022-discovery-topic-equity-card'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-post', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/post"+'.vue')), 'pages-app2022-discovery-post'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-post-category', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/post-category"+'.vue')), 'pages-app2022-discovery-post-category'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-topic-exchange', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/topic-exchange"+'.vue')), 'pages-app2022-discovery-topic-exchange'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-calendar', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/calendar"+'.vue')), 'pages-app2022-discovery-calendar'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-big-shot', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/big-shot"+'.vue')), 'pages-app2022-discovery-big-shot'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-ai-trainer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/ai-trainer"+'.vue')), 'pages-app2022-discovery-ai-trainer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-confirm', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/order/confirm"+'.vue')), 'pages-order-confirm'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/order/list"+'.vue')), 'pages-order-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-discovery-offline-course-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/discovery/offline-course-detail"+'.vue')), 'pages-app2022-discovery-offline-course-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-company-live-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/company-live/list"+'.vue')), 'pages-company-live-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-company-live-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/company-live/detail"+'.vue')), 'pages-company-live-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-company-live-room', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/company-live/room"+'.vue')), 'pages-company-live-room'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('sso', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/sso"+'.vue')), 'sso'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('error', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/error"+'.vue')), 'error'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-coupon', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/user/coupon"+'.vue')), 'pages-user-coupon'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturer"+'.vue')), 'pages-lecturer-lecturer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturerDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturerDetail"+'.vue')), 'pages-lecturer-lecturerDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturerInfo', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturerInfo"+'.vue')), 'pages-lecturer-lecturerInfo'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturerSchedule', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturerSchedule"+'.vue')), 'pages-lecturer-lecturerSchedule'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturerEval', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturerEval"+'.vue')), 'pages-lecturer-lecturerEval'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-lecturer-lecturerFraction', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/lecturer/lecturerFraction"+'.vue')), 'pages-lecturer-lecturerFraction'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseRecommend', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseRecommend"+'.vue')), 'pages-app2022-CourseRecommend'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseNavArea', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseNavArea"+'.vue')), 'pages-app2022-CourseNavArea'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-OmoNavArea', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/OmoNavArea"+'.vue')), 'pages-trainProgram-OmoNavArea'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseRecommend', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseRecommend"+'.vue')), 'pages-app2022-CourseRecommend'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app2022-CourseNavArea', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/app2022/CourseNavArea"+'.vue')), 'pages-app2022-CourseNavArea'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-trainProgram-OmoNavArea', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/trainProgram/OmoNavArea"+'.vue')), 'pages-trainProgram-OmoNavArea'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-ojt-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/ojt/list"+'.vue')), 'pages-ojt-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-ojt-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/ojt/detail"+'.vue')), 'pages-ojt-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-mentor-info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/mentor/info"+'.vue')), 'pages-mentor-info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-mentor-task', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/mentor/task"+'.vue')), 'pages-mentor-task'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-mentor-task-staff', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/root/workspace/quantum-h5-uni-cli_daec/src/pages/mentor/task-staff"+'.vue')), 'pages-mentor-task-staff'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/app2022/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"量见云课堂","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-app2022-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-app2022-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/index/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/app2022/leaderboardDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"排行榜","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-leaderboardDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-leaderboardDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/leaderboardDetail',
  windowTop:0
}
},
{
path: '/pages/app2022/PendingTask',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"待完成任务","enablePullDownRefresh":false,"backgroundColor":"#f5f8fa"})
      },
      [
        createElement('pages-app2022-PendingTask', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-PendingTask',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/PendingTask',
  windowTop:44
}
},
{
path: '/pages/app2022/study/StudyHeatBoard',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习热度榜Top10","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-study-StudyHeatBoard', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-StudyHeatBoard',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/StudyHeatBoard',
  windowTop:0
}
},
{
path: '/pages/app2022/study/CreditDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"已获学分","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-CreditDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-CreditDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/CreditDetail',
  windowTop:44
}
},
{
path: '/pages/app2022/study/CreditDetailFilter',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"详细数据","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-CreditDetailFilter', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-CreditDetailFilter',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/CreditDetailFilter',
  windowTop:44
}
},
{
path: '/pages/app2022/CompanyNews',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业资讯","enablePullDownRefresh":false,"backgroundColor":"#f5f8fa"})
      },
      [
        createElement('pages-app2022-CompanyNews', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CompanyNews',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CompanyNews',
  windowTop:44
}
},
{
path: '/pages/app2022/CompanyNewsDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业资讯","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CompanyNewsDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CompanyNewsDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CompanyNewsDetail',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/Discovery',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"发现","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-discovery-Discovery', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-app2022-discovery-Discovery',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/Discovery',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/app2022/discovery/module-content',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-module-content', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-module-content',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/module-content',
  windowTop:44
}
},
{
path: '/pages/app2022/LimitDiscounts',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"限时折扣","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-LimitDiscounts', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-LimitDiscounts',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/LimitDiscounts',
  windowTop:0
}
},
{
path: '/pages/app2022/study/Study',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:2
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-study-Study', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:3,
  name:'pages-app2022-study-Study',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/Study',
isQuit:true,
isTabBar:true,
tabBarIndex:2,
  windowTop:0
}
},
{
path: '/pages/app2022/CampAttended',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"参加的训练营","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-CampAttended', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CampAttended',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CampAttended',
  windowTop:0
}
},
{
path: '/pages/app2022/study/StudyReport',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习报告","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-study-StudyReport', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-StudyReport',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/StudyReport',
  windowTop:0
}
},
{
path: '/pages/app2022/study/StudyReportDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"详细数据","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-StudyReportDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-StudyReportDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/StudyReportDetail',
  windowTop:44
}
},
{
path: '/pages/app2022/study/StudyRecently',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"最近学习","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-StudyRecently', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-StudyRecently',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/StudyRecently',
  windowTop:44
}
},
{
path: '/pages/app2022/CampNewest',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"热门训练营","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CampNewest', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CampNewest',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CampNewest',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseNewest',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"好课上新","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseNewest', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseNewest',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseNewest',
  windowTop:44
}
},
{
path: '/pages/app2022/WeekCampTop10',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"热门训练营 Top10","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-WeekCampTop10', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-WeekCampTop10',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/WeekCampTop10',
  windowTop:44
}
},
{
path: '/pages/app2022/filterCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程分类","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-filterCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-filterCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/filterCourse',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseStudy',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"已学课程","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseStudy', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseStudy',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseStudy',
  windowTop:44
}
},
{
path: '/pages/app2022/CompanyNewsExLink',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"资讯详情","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-CompanyNewsExLink', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CompanyNewsExLink',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CompanyNewsExLink',
  windowTop:0
}
},
{
path: '/pages/app2022/WeekCourseTop10',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"每周热门课程top10","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-WeekCourseTop10', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-WeekCourseTop10',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/WeekCourseTop10',
  windowTop:0
}
},
{
path: '/pages/app2022/RecommendTopic',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专题详情","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-RecommendTopic', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-RecommendTopic',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/RecommendTopic',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/celebrityColumn',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"名师专栏","navigationBarBackgroundColor":"#467abc","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-celebrityColumn', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-celebrityColumn',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/celebrityColumn',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/teacherDetails',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarBackgroundColor":"#467abc","navigationBarTitleText":"名师详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-teacherDetails', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-teacherDetails',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/teacherDetails',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/realBattlefield',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarBackgroundColor":"#467abc","navigationBarTitleText":"实战场","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-realBattlefield', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-realBattlefield',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/realBattlefield',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/professionalCollege',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarBackgroundColor":"#467abc","navigationBarTitleText":"专业学院","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-professionalCollege', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-professionalCollege',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/professionalCollege',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"搜索","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-search',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/search',
  windowTop:0
}
},
{
path: '/pages/user/userCenter',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:3
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人中心","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userCenter', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:4,
  name:'pages-user-userCenter',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userCenter',
isQuit:true,
isTabBar:true,
tabBarIndex:3,
  windowTop:0
}
},
{
path: '/pages/studyplan/studyplan',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"学习计划","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-studyplan-studyplan', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-studyplan-studyplan',
  isNVue:false,maxWidth:0,
  pagePath:'pages/studyplan/studyplan',
  windowTop:0
}
},
{
path: '/pages/studyplan/studyplandetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"学习计划详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-studyplan-studyplandetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-studyplan-studyplandetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/studyplan/studyplandetail',
  windowTop:0
}
},
{
path: '/pages/studyplan/courseProgress',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"课程进度","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-studyplan-courseProgress', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-studyplan-courseProgress',
  isNVue:false,maxWidth:0,
  pagePath:'pages/studyplan/courseProgress',
  windowTop:0
}
},
{
path: '/pages/enterpriseCourse/enterpriseCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业课堂","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-enterpriseCourse-enterpriseCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-enterpriseCourse-enterpriseCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/enterpriseCourse/enterpriseCourse',
  windowTop:0
}
},
{
path: '/pages/enterpriseCourse/recommendCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"推荐课程","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-enterpriseCourse-recommendCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-enterpriseCourse-recommendCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/enterpriseCourse/recommendCourse',
  windowTop:0
}
},
{
path: '/pages/index/companyLiveVideo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"企业直播","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-index-companyLiveVideo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-companyLiveVideo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/companyLiveVideo',
  windowTop:0
}
},
{
path: '/pages/testPaper/testPaper',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"测试详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-testPaper-testPaper', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-testPaper-testPaper',
  isNVue:false,maxWidth:0,
  pagePath:'pages/testPaper/testPaper',
  windowTop:0
}
},
{
path: '/pages/testPaper/testResult',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"测试结果","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-testPaper-testResult', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-testPaper-testResult',
  isNVue:false,maxWidth:0,
  pagePath:'pages/testPaper/testResult',
  windowTop:0
}
},
{
path: '/pages/testPaper/questionNaire',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"答题中","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-testPaper-questionNaire', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-testPaper-questionNaire',
  isNVue:false,maxWidth:0,
  pagePath:'pages/testPaper/questionNaire',
  windowTop:0
}
},
{
path: '/pages/liveVideo/liveVideo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"直播详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-liveVideo-liveVideo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-liveVideo-liveVideo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/liveVideo/liveVideo',
  windowTop:0
}
},
{
path: '/pages/enterpriseCourse/courseAudio',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-enterpriseCourse-courseAudio', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-enterpriseCourse-courseAudio',
  isNVue:false,maxWidth:0,
  pagePath:'pages/enterpriseCourse/courseAudio',
  windowTop:0
}
},
{
path: '/pages/enterpriseCourse/courseVideo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-enterpriseCourse-courseVideo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-enterpriseCourse-courseVideo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/enterpriseCourse/courseVideo',
  windowTop:0
}
},
{
path: '/pages/user/userOrder',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"我的购买","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userOrder', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userOrder',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userOrder',
  windowTop:0
}
},
{
path: '/pages/user/userFavorite',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"我的收藏","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userFavorite', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userFavorite',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userFavorite',
  windowTop:0
}
},
{
path: '/pages/user/userFeedback',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"帮助与反馈","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userFeedback', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userFeedback',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userFeedback',
  windowTop:0
}
},
{
path: '/pages/user/userCertificate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"我的证书","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userCertificate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userCertificate',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userCertificate',
  windowTop:0
}
},
{
path: '/pages/user/userCertificateDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"我的证书","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userCertificateDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userCertificateDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userCertificateDetail',
  windowTop:0
}
},
{
path: '/pages/user/userCertificateGoShare',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userCertificateGoShare', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userCertificateGoShare',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userCertificateGoShare',
  windowTop:0
}
},
{
path: '/pages/user/userCertificateShare',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userCertificateShare', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userCertificateShare',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userCertificateShare',
  windowTop:0
}
},
{
path: '/pages/user/userFocus',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"关注与收藏","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userFocus', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userFocus',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userFocus',
  windowTop:0
}
},
{
path: '/pages/user/userStudyRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"成长地图","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userStudyRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userStudyRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userStudyRecord',
  windowTop:0
}
},
{
path: '/pages/user/userFile',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业资料","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userFile', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userFile',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userFile',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/liveColumn',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商学院","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-liveColumn', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-liveColumn',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/liveColumn',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/embaDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"EMBA大课","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-embaDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-embaDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/embaDetail',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/teacherHall',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"名师堂","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-teacherHall', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-teacherHall',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/teacherHall',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/teacherHallDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"名师堂","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-teacherHallDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-teacherHallDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/teacherHallDetail',
  windowTop:0
}
},
{
path: '/pages/index/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-index-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/login',
  windowTop:0
}
},
{
path: '/pages/index/umsCu',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录中","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-index-umsCu', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-umsCu',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/umsCu',
  windowTop:0
}
},
{
path: '/pages/examEvaluation/trainList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"线下培训","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-examEvaluation-trainList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-examEvaluation-trainList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/examEvaluation/trainList',
  windowTop:0
}
},
{
path: '/pages/examEvaluation/trainDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-examEvaluation-trainDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-examEvaluation-trainDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/examEvaluation/trainDetail',
  windowTop:0
}
},
{
path: '/pages/exam/examEvaluation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"考试评估","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-exam-examEvaluation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-examEvaluation',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/examEvaluation',
  windowTop:0
}
},
{
path: '/pages/exam/rank',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试排行榜"})
      },
      [
        createElement('pages-exam-rank', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-rank',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/rank',
  windowTop:44
}
},
{
path: '/pages/exam/examDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-exam-examDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-examDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/examDetail',
  windowTop:0
}
},
{
path: '/pages/exam/examPaper',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"答题中","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-exam-examPaper', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-examPaper',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/examPaper',
  windowTop:0
}
},
{
path: '/pages/exam/examReview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"结果"})
      },
      [
        createElement('pages-exam-examReview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-examReview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/examReview',
  windowTop:44
}
},
{
path: '/pages/exam/examRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"记录"})
      },
      [
        createElement('pages-exam-examRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-examRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/examRecord',
  windowTop:44
}
},
{
path: '/pages/exam/external',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"外部考试"})
      },
      [
        createElement('pages-exam-external', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-external',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/external',
  windowTop:44
}
},
{
path: '/pages/user/trainingCamp',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"训练营","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-trainingCamp', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-trainingCamp',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/trainingCamp',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/collectionDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"合集","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-collectionDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-collectionDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/collectionDetail',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/vocationalSkills',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"职业技能","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-vocationalSkills', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-vocationalSkills',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/vocationalSkills',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/vocationalSkillsII',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"职业技能","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-vocationalSkillsII', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-vocationalSkillsII',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/vocationalSkillsII',
  windowTop:0
}
},
{
path: '/pages/user/changeCompany',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"切换企业","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-changeCompany', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-changeCompany',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/changeCompany',
  windowTop:0
}
},
{
path: '/pages/user/vipHistory',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"开通记录","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-vipHistory', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-vipHistory',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/vipHistory',
  windowTop:0
}
},
{
path: '/pages/user/trainingCampDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"星云训练营","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-trainingCampDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-trainingCampDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/trainingCampDetail',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxHomePage',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"中国职业经理人协会高级研修院"})
      },
      [
        createElement('pages-zzx-zzxHomePage', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxHomePage',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxHomePage',
  windowTop:44
}
},
{
path: '/pages/user/userStudyRecordCAPM',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarTitleText":"学习记录","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-userStudyRecordCAPM', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userStudyRecordCAPM',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userStudyRecordCAPM',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxOrderConfirm',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"订单确认","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-zzx-zzxOrderConfirm', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxOrderConfirm',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxOrderConfirm',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxChooseAddress',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"地址选择","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-zzx-zzxChooseAddress', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxChooseAddress',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxChooseAddress',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxChooseCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"选课","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-zzx-zzxChooseCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxChooseCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxChooseCourse',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxChooseCourseConfirm',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程确认","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-zzx-zzxChooseCourseConfirm', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxChooseCourseConfirm',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxChooseCourseConfirm',
  windowTop:0
}
},
{
path: '/pages/zzx/zzxStudyCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom"})
      },
      [
        createElement('pages-zzx-zzxStudyCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-zzxStudyCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/zzxStudyCourse',
  windowTop:0
}
},
{
path: '/pages/zzx/annualReview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"证书年审"})
      },
      [
        createElement('pages-zzx-annualReview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-zzx-annualReview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/zzx/annualReview',
  windowTop:44
}
},
{
path: '/pages/knowledgeSpace/filterCategoryDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程筛选","navigationStyle":"custom"})
      },
      [
        createElement('pages-knowledgeSpace-filterCategoryDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-filterCategoryDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/filterCategoryDetail',
  windowTop:0
}
},
{
path: '/pages/knowledgeSpace/zhuanti',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专题","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-knowledgeSpace-zhuanti', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-knowledgeSpace-zhuanti',
  isNVue:false,maxWidth:0,
  pagePath:'pages/knowledgeSpace/zhuanti',
  windowTop:0
}
},
{
path: '/pages/qychat/specialTopicDetails',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarBackgroundColor":"#467abc","navigationBarTitleText":"特色专题","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-qychat-specialTopicDetails', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-qychat-specialTopicDetails',
  isNVue:false,maxWidth:0,
  pagePath:'pages/qychat/specialTopicDetails',
  windowTop:0
}
},
{
path: '/pages/qychat/specialTopic',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarBackgroundColor":"#467abc","navigationBarTitleText":"专题列表","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-qychat-specialTopic', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-qychat-specialTopic',
  isNVue:false,maxWidth:0,
  pagePath:'pages/qychat/specialTopic',
  windowTop:0
}
},
{
path: '/pages/live/liveCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"直播课","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-live-liveCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-live-liveCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/live/liveCourse',
  windowTop:0
}
},
{
path: '/pages/live/live',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"直播课","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-live-live', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-live-live',
  isNVue:false,maxWidth:0,
  pagePath:'pages/live/live',
  windowTop:0
}
},
{
path: '/pages/index/selectHobby',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"兴趣选择","enablePullDownRefresh":false})
      },
      [
        createElement('pages-index-selectHobby', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-selectHobby',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/selectHobby',
  windowTop:44
}
},
{
path: '/pages/lebo/lebo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":true,"navigationBarBackgroundColor":"#292b37","navigationBarTitleText":"学院详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-lebo-lebo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lebo-lebo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lebo/lebo',
  windowTop:0
}
},
{
path: '/pages/questionnaire/questionnaireList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"调查问卷","enablePullDownRefresh":true,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-questionnaire-questionnaireList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-questionnaire-questionnaireList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/questionnaire/questionnaireList',
  windowTop:0
}
},
{
path: '/pages/questionnaire/questionnaireDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"调查问卷","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-questionnaire-questionnaireDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-questionnaire-questionnaireDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/questionnaire/questionnaireDetail',
  windowTop:0
}
},
{
path: '/pages/trainCamp/trainCampDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-trainCamp-trainCampDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCamp-trainCampDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCamp/trainCampDetail',
  windowTop:0
}
},
{
path: '/pages/trainCamp/trainCampVideoPlayer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-trainCamp-trainCampVideoPlayer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCamp-trainCampVideoPlayer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCamp/trainCampVideoPlayer',
  windowTop:0
}
},
{
path: '/pages/user/studyConsultant',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专属学习顾问","enablePullDownRefresh":false,"navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-studyConsultant', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-studyConsultant',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/studyConsultant',
  windowTop:0
}
},
{
path: '/pages/trainOnline/onlineList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习计划","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainOnline-onlineList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainOnline-onlineList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainOnline/onlineList',
  windowTop:0
}
},
{
path: '/pages/resources/companyCourse',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-resources-companyCourse', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-resources-companyCourse',
  isNVue:false,maxWidth:0,
  pagePath:'pages/resources/companyCourse',
  windowTop:0
}
},
{
path: '/pages/user/vipIntroduce',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"会员中心","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-user-vipIntroduce', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-vipIntroduce',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/vipIntroduce',
  windowTop:0
}
},
{
path: '/pages/user/vipClass',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"量见超级会员专区","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-user-vipClass', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-vipClass',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/vipClass',
  windowTop:0
}
},
{
path: '/pages/protocol/protocol',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-protocol-protocol', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-protocol-protocol',
  isNVue:false,maxWidth:0,
  pagePath:'pages/protocol/protocol',
  windowTop:0
}
},
{
path: '/pages/media-play/video',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-media-play-video', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-media-play-video',
  isNVue:false,maxWidth:0,
  pagePath:'pages/media-play/video',
  windowTop:0
}
},
{
path: '/pages/media-play/knowledge',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-media-play-knowledge', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-media-play-knowledge',
  isNVue:false,maxWidth:0,
  pagePath:'pages/media-play/knowledge',
  windowTop:0
}
},
{
path: '/pages/courseDetail/courseDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-courseDetail-courseDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-courseDetail-courseDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/courseDetail/courseDetail',
  windowTop:0
}
},
{
path: '/pages/user/userAccount',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"账号与安全","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-userAccount', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-userAccount',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/userAccount',
  windowTop:44
}
},
{
path: '/pages/user/changeAccount',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"更改账号","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-changeAccount', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-changeAccount',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/changeAccount',
  windowTop:44
}
},
{
path: '/pages/user/changePass',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"更改密码","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-changePass', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-changePass',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/changePass',
  windowTop:44
}
},
{
path: '/pages/user/changeEmail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"更改邮箱","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-changeEmail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-changeEmail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/changeEmail',
  windowTop:44
}
},
{
path: '/pages/user/faceVerify',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"人脸识别","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-faceVerify', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-faceVerify',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/faceVerify',
  windowTop:44
}
},
{
path: '/pages/trainCamp/trainCampWord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainCamp-trainCampWord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCamp-trainCampWord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCamp/trainCampWord',
  windowTop:0
}
},
{
path: '/pages/planTest/testInfo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"测试","enablePullDownRefresh":false})
      },
      [
        createElement('pages-planTest-testInfo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-planTest-testInfo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/planTest/testInfo',
  windowTop:44
}
},
{
path: '/pages/trainOnline/spacex',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainOnline-spacex', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainOnline-spacex',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainOnline/spacex',
  windowTop:0
}
},
{
path: '/pages/trainOnline/spacexDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainOnline-spacexDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainOnline-spacexDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainOnline/spacexDetail',
  windowTop:0
}
},
{
path: '/pages/trainOnline/SpaceChart',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarBackgroundColor":"#0c1245","navigationBarTextStyle":"white","navigationBarTitleText":"排行榜","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainOnline-SpaceChart', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainOnline-SpaceChart',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainOnline/SpaceChart',
  windowTop:0
}
},
{
path: '/pages/trainOnline/SpaceHome',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainOnline-SpaceHome', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainOnline-SpaceHome',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainOnline/SpaceHome',
  windowTop:0
}
},
{
path: '/pages/user/newUserCertificate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"我的证书","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-newUserCertificate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-newUserCertificate',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/newUserCertificate',
  windowTop:0
}
},
{
path: '/pages/user/newUserCertificateDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"enablePullDownRefresh":false,"navigationBarTitleText":"证书详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-user-newUserCertificateDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-newUserCertificateDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/newUserCertificateDetail',
  windowTop:0
}
},
{
path: '/pages/trainCampNew/TrainCampPeriod',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"训练营分类","enablePullDownRefresh":true})
      },
      [
        createElement('pages-trainCampNew-TrainCampPeriod', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-TrainCampPeriod',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/TrainCampPeriod',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/PeriodPreview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"训练营","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-PeriodPreview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-PeriodPreview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/PeriodPreview',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/PeriodDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainCampNew-PeriodDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-PeriodDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/PeriodDetail',
  windowTop:0
}
},
{
path: '/pages/trainCampNew/IntegralDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分明细","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-IntegralDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-IntegralDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/IntegralDetail',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/ScheduleDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"打卡进度","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-ScheduleDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-ScheduleDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/ScheduleDetail',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/GrandDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"累计学习时长明细","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-GrandDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-GrandDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/GrandDetail',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/FeatureCase',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"案例精选","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-FeatureCase', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-FeatureCase',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/FeatureCase',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/KnowledgeCard',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"知识卡片","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-KnowledgeCard', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-KnowledgeCard',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/KnowledgeCard',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/ChosenHomework',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业精选","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-ChosenHomework', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-ChosenHomework',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/ChosenHomework',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/GraphicsFiles',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainCampNew-level-GraphicsFiles', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-GraphicsFiles',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/GraphicsFiles',
  windowTop:0
}
},
{
path: '/pages/trainCampNew/level/Operation/PreviewDocument',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"文档预览","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-Operation-PreviewDocument', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-PreviewDocument',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/PreviewDocument',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/test/Result',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"练测结果","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-test-Result', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-test-Result',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/test/Result',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/test/StartTest',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"测试","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-test-StartTest', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-test-StartTest',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/test/StartTest',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/test/TestRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全部测试记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-test-TestRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-test-TestRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/test/TestRecord',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/test/TestPaper',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-test-TestPaper', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-test-TestPaper',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/test/TestPaper',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/test/TestReview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-trainCampNew-level-test-TestReview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-test-TestReview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/test/TestReview',
  windowTop:0
}
},
{
path: '/pages/trainCampNew/level/Operation/Operation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-Operation-Operation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-Operation',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/Operation',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/Operation/OperationAll',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"已完成作业列表","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-Operation-OperationAll', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-OperationAll',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/OperationAll',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/Operation/OperationDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业详情","enablePullDownRefresh":true})
      },
      [
        createElement('pages-trainCampNew-level-Operation-OperationDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-OperationDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/OperationDetail',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/Operation/AllReply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全部评论","enablePullDownRefresh":true})
      },
      [
        createElement('pages-trainCampNew-level-Operation-AllReply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-AllReply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/AllReply',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/Operation/OperationSubmit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"提交作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-Operation-OperationSubmit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Operation-OperationSubmit',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Operation/OperationSubmit',
  windowTop:44
}
},
{
path: '/pages/trainCampNew/level/Questionnaire',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问卷","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainCampNew-level-Questionnaire', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainCampNew-level-Questionnaire',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainCampNew/level/Questionnaire',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainOmoList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训项目","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainOmoList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainOmoList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainOmoList',
  windowTop:44
}
},
{
path: '/pages/trainProgram/sign',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"报名管理","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-sign', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-sign',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/sign',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainOmoDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训项目详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainOmoDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainOmoDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainOmoDetail',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainOfflineClass',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"线下课","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainOfflineClass', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainOfflineClass',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainOfflineClass',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainOfflineAssess',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"线下考核","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainOfflineAssess', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainOfflineAssess',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainOfflineAssess',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainCheckRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"签到记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainCheckRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainCheckRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainCheckRecord',
  windowTop:44
}
},
{
path: '/pages/trainProgram/TrainCheckIn',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考勤","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-TrainCheckIn', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-TrainCheckIn',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/TrainCheckIn',
  windowTop:44
}
},
{
path: '/pages/trainProgram/homework/Homework',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-homework-Homework', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-homework-Homework',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/homework/Homework',
  windowTop:44
}
},
{
path: '/pages/trainProgram/homework/HomeworkDemand',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-homework-HomeworkDemand', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-homework-HomeworkDemand',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/homework/HomeworkDemand',
  windowTop:44
}
},
{
path: '/pages/trainProgram/homework/HomeworkDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-homework-HomeworkDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-homework-HomeworkDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/homework/HomeworkDetail',
  windowTop:44
}
},
{
path: '/pages/trainProgram/homework/HomeworkSubmit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-homework-HomeworkSubmit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-homework-HomeworkSubmit',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/homework/HomeworkSubmit',
  windowTop:44
}
},
{
path: '/pages/trainProgram/homework/AllReply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"作业","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-homework-AllReply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-homework-AllReply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/homework/AllReply',
  windowTop:44
}
},
{
path: '/pages/search/Search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"搜索","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-search-Search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-search-Search',
  isNVue:false,maxWidth:0,
  pagePath:'pages/search/Search',
  windowTop:0
}
},
{
path: '/pages/integralMall/mall',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分商城","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-mall', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-mall',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/mall',
  windowTop:44
}
},
{
path: '/pages/AllMedia/Index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全媒体运营师","enablePullDownRefresh":false})
      },
      [
        createElement('pages-AllMedia-Index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-AllMedia-Index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/AllMedia/Index',
  windowTop:44
}
},
{
path: '/pages/AllMedia/Practical',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"实操班","enablePullDownRefresh":false})
      },
      [
        createElement('pages-AllMedia-Practical', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-AllMedia-Practical',
  isNVue:false,maxWidth:0,
  pagePath:'pages/AllMedia/Practical',
  windowTop:44
}
},
{
path: '/pages/AllMedia/Certificate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考证班","enablePullDownRefresh":false})
      },
      [
        createElement('pages-AllMedia-Certificate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-AllMedia-Certificate',
  isNVue:false,maxWidth:0,
  pagePath:'pages/AllMedia/Certificate',
  windowTop:44
}
},
{
path: '/pages/app2022/study/CheckRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"打卡记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-CheckRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-CheckRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/CheckRecord',
  windowTop:44
}
},
{
path: '/pages/app2022/study/CheckRank',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"打卡排行榜","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-study-CheckRank', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-CheckRank',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/CheckRank',
  windowTop:0
}
},
{
path: '/pages/myKnowledge/List',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的知识","enablePullDownRefresh":false})
      },
      [
        createElement('pages-myKnowledge-List', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-myKnowledge-List',
  isNVue:false,maxWidth:0,
  pagePath:'pages/myKnowledge/List',
  windowTop:44
}
},
{
path: '/pages/myKnowledge/UploadKnowledge',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"上传知识","enablePullDownRefresh":false})
      },
      [
        createElement('pages-myKnowledge-UploadKnowledge', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-myKnowledge-UploadKnowledge',
  isNVue:false,maxWidth:0,
  pagePath:'pages/myKnowledge/UploadKnowledge',
  windowTop:44
}
},
{
path: '/pages/myKnowledge/ChooseCover',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"系统推荐","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-myKnowledge-ChooseCover', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-myKnowledge-ChooseCover',
  isNVue:false,maxWidth:0,
  pagePath:'pages/myKnowledge/ChooseCover',
  windowTop:0
}
},
{
path: '/pages/myKnowledge/Comments',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"评论","enablePullDownRefresh":false})
      },
      [
        createElement('pages-myKnowledge-Comments', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-myKnowledge-Comments',
  isNVue:false,maxWidth:0,
  pagePath:'pages/myKnowledge/Comments',
  windowTop:44
}
},
{
path: '/pages/myKnowledge/AllCommentsReply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全部回复","enablePullDownRefresh":false})
      },
      [
        createElement('pages-myKnowledge-AllCommentsReply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-myKnowledge-AllCommentsReply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/myKnowledge/AllCommentsReply',
  windowTop:44
}
},
{
path: '/pages/integralMall/MallCenter',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分商城","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-integralMall-MallCenter', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-MallCenter',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/MallCenter',
  windowTop:0
}
},
{
path: '/pages/integralMall/Rules',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分获取规则","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-Rules', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-Rules',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/Rules',
  windowTop:44
}
},
{
path: '/pages/integralMall/Records',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分明细","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-Records', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-Records',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/Records',
  windowTop:44
}
},
{
path: '/pages/integralMall/Exchange',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"兑换积分","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-Exchange', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-Exchange',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/Exchange',
  windowTop:44
}
},
{
path: '/pages/integralMall/Order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的订单","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-Order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-Order',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/Order',
  windowTop:44
}
},
{
path: '/pages/integralMall/OrderDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-OrderDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-OrderDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/OrderDetail',
  windowTop:44
}
},
{
path: '/pages/integralMall/CommodityDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-integralMall-CommodityDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-integralMall-CommodityDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/integralMall/CommodityDetail',
  windowTop:44
}
},
{
path: '/pages/index/autoLogin',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-index-autoLogin', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-autoLogin',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/autoLogin',
  windowTop:0
}
},
{
path: '/pages/cloudCourse/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-cloudCourse-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-cloudCourse-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/cloudCourse/index',
  windowTop:0
}
},
{
path: '/pages/examEvaluation/offlineTrainCheck',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"签到","enablePullDownRefresh":false})
      },
      [
        createElement('pages-examEvaluation-offlineTrainCheck', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-examEvaluation-offlineTrainCheck',
  isNVue:false,maxWidth:0,
  pagePath:'pages/examEvaluation/offlineTrainCheck',
  windowTop:44
}
},
{
path: '/pages/payCenter/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"支付中心","enablePullDownRefresh":false})
      },
      [
        createElement('pages-payCenter-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-payCenter-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/payCenter/index',
  windowTop:44
}
},
{
path: '/pages/miniMBA/intro',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"EMBA","enablePullDownRefresh":false})
      },
      [
        createElement('pages-miniMBA-intro', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-miniMBA-intro',
  isNVue:false,maxWidth:0,
  pagePath:'pages/miniMBA/intro',
  windowTop:44
}
},
{
path: '/pages/miniMBA/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"EMBA","enablePullDownRefresh":false})
      },
      [
        createElement('pages-miniMBA-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-miniMBA-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/miniMBA/detail',
  windowTop:44
}
},
{
path: '/pages/ecologyLogin/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-ecologyLogin-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-ecologyLogin-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/ecologyLogin/index',
  windowTop:0
}
},
{
path: '/pages/talent/talent',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-talent-talent', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-talent-talent',
  isNVue:false,maxWidth:0,
  pagePath:'pages/talent/talent',
  windowTop:0
}
},
{
path: '/pages/talent/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-talent-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-talent-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/talent/detail',
  windowTop:0
}
},
{
path: '/pages/app2022/study/StudyRules',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习规则","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-study-StudyRules', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-study-StudyRules',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/study/StudyRules',
  windowTop:44
}
},
{
path: '/pages/index/resetPassword',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"忘记密码","enablePullDownRefresh":false})
      },
      [
        createElement('pages-index-resetPassword', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-resetPassword',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/resetPassword',
  windowTop:44
}
},
{
path: '/pages/arena/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"头脑竞技场","enablePullDownRefresh":false})
      },
      [
        createElement('pages-arena-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/list',
  windowTop:44
}
},
{
path: '/pages/arena/record',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"闯关记录","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-record', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-record',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/record',
  windowTop:0
}
},
{
path: '/pages/arena/rank',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"排行榜","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-rank', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-rank',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/rank',
  windowTop:0
}
},
{
path: '/pages/arena/level',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"知识大闯关","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-level', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-level',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/level',
  windowTop:0
}
},
{
path: '/pages/arena/answer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-answer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-answer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/answer',
  windowTop:0
}
},
{
path: '/pages/arena/pk',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-pk', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-pk',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/pk',
  windowTop:0
}
},
{
path: '/pages/arena/review',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-review', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-review',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/review',
  windowTop:0
}
},
{
path: '/pages/arena/answerPK',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-arena-answerPK', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-arena-answerPK',
  isNVue:false,maxWidth:0,
  pagePath:'pages/arena/answerPK',
  windowTop:0
}
},
{
path: '/pages/examEvaluation/offlineFiles',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训资料","enablePullDownRefresh":false})
      },
      [
        createElement('pages-examEvaluation-offlineFiles', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-examEvaluation-offlineFiles',
  isNVue:false,maxWidth:0,
  pagePath:'pages/examEvaluation/offlineFiles',
  windowTop:44
}
},
{
path: '/pages/exam/practiceRecord',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"答题记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-exam-practiceRecord', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-practiceRecord',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/practiceRecord',
  windowTop:44
}
},
{
path: '/pages/exam/practicePaper',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"随堂测练","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-exam-practicePaper', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-practicePaper',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/practicePaper',
  windowTop:0
}
},
{
path: '/pages/exam/practiceReview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"答题详情","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-exam-practiceReview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-exam-practiceReview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/exam/practiceReview',
  windowTop:0
}
},
{
path: '/pages/microCourse/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/index',
  windowTop:0
}
},
{
path: '/pages/microCourse/graphic',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"微课","enablePullDownRefresh":false})
      },
      [
        createElement('pages-microCourse-graphic', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-graphic',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/graphic',
  windowTop:44
}
},
{
path: '/pages/microCourse/graphicDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"微课","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-graphicDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-graphicDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/graphicDetail',
  windowTop:0
}
},
{
path: '/pages/microCourse/video',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-video', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-video',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/video',
  windowTop:0
}
},
{
path: '/pages/microCourse/allReply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全部回复","enablePullDownRefresh":false})
      },
      [
        createElement('pages-microCourse-allReply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-allReply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/allReply',
  windowTop:44
}
},
{
path: '/pages/microCourse/create/create',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"创建微课","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-create-create', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-create-create',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/create/create',
  windowTop:0
}
},
{
path: '/pages/microCourse/create/baseInfo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"创建微课","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-create-baseInfo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-create-baseInfo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/create/baseInfo',
  windowTop:0
}
},
{
path: '/pages/microCourse/create/systemCover',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"系统推荐","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-create-systemCover', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-create-systemCover',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/create/systemCover',
  windowTop:0
}
},
{
path: '/pages/microCourse/create/contentAdd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"添加微课内容","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-create-contentAdd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-create-contentAdd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/create/contentAdd',
  windowTop:0
}
},
{
path: '/pages/microCourse/create/videoAdd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"添加微课内容","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-microCourse-create-videoAdd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-microCourse-create-videoAdd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/microCourse/create/videoAdd',
  windowTop:0
}
},
{
path: '/pages/cacheClean',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-cacheClean', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-cacheClean',
  isNVue:false,maxWidth:0,
  pagePath:'pages/cacheClean',
  windowTop:0
}
},
{
path: '/pages/app2022/AiChat',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-app2022-AiChat', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-AiChat',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/AiChat',
  windowTop:0
}
},
{
path: '/pages/SJTU/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-SJTU-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-SJTU-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/SJTU/index',
  windowTop:0
}
},
{
path: '/pages/SJTU/board',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"排行榜","enablePullDownRefresh":false})
      },
      [
        createElement('pages-SJTU-board', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-SJTU-board',
  isNVue:false,maxWidth:0,
  pagePath:'pages/SJTU/board',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/topic-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专题详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-topic-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-topic-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/topic-detail',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/select-hobby',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个性化推荐","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-select-hobby', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-select-hobby',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/select-hobby',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/content-more',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"更多","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-content-more', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-content-more',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/content-more',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/module-category',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-module-category', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-module-category',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/module-category',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/topic-equity-card',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专题权益卡","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-topic-equity-card', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-topic-equity-card',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/topic-equity-card',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/post',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"热门岗位","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-post', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-post',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/post',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/post-category',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-post-category', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-post-category',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/post-category',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/topic-exchange',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专题权益","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-topic-exchange', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-topic-exchange',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/topic-exchange',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/calendar',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学习日历","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-calendar', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-calendar',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/calendar',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/big-shot',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"大咖课","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-big-shot', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-big-shot',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/big-shot',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/ai-trainer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"人工智能训练师","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-ai-trainer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-ai-trainer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/ai-trainer',
  windowTop:44
}
},
{
path: '/pages/order/confirm',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"确认订单","enablePullDownRefresh":false})
      },
      [
        createElement('pages-order-confirm', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-confirm',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/confirm',
  windowTop:44
}
},
{
path: '/pages/order/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的订单","enablePullDownRefresh":false})
      },
      [
        createElement('pages-order-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/list',
  windowTop:44
}
},
{
path: '/pages/app2022/discovery/offline-course-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"线下课","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-discovery-offline-course-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-discovery-offline-course-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/discovery/offline-course-detail',
  windowTop:44
}
},
{
path: '/pages/company-live/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业直播","enablePullDownRefresh":true})
      },
      [
        createElement('pages-company-live-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-company-live-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/company-live/list',
  windowTop:44
}
},
{
path: '/pages/company-live/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业直播","enablePullDownRefresh":true})
      },
      [
        createElement('pages-company-live-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-company-live-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/company-live/detail',
  windowTop:44
}
},
{
path: '/pages/company-live/room',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业直播房间","enablePullDownRefresh":false})
      },
      [
        createElement('pages-company-live-room', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-company-live-room',
  isNVue:false,maxWidth:0,
  pagePath:'pages/company-live/room',
  windowTop:44
}
},
{
path: '/sso',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录中","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('sso', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'sso',
  isNVue:false,maxWidth:0,
  pagePath:'sso',
  windowTop:0
}
},
{
path: '/error',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('error', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'error',
  isNVue:false,maxWidth:0,
  pagePath:'error',
  windowTop:0
}
},
{
path: '/pages/user/coupon',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠券","enablePullDownRefresh":false})
      },
      [
        createElement('pages-user-coupon', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-coupon',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/coupon',
  windowTop:44
}
},
{
path: '/pages/lecturer/lecturer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师风采","enablePullDownRefresh":false})
      },
      [
        createElement('pages-lecturer-lecturer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturer',
  windowTop:44
}
},
{
path: '/pages/lecturer/lecturerDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-lecturer-lecturerDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturerDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturerDetail',
  windowTop:44
}
},
{
path: '/pages/lecturer/lecturerInfo',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师信息","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-lecturer-lecturerInfo', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturerInfo',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturerInfo',
  windowTop:0
}
},
{
path: '/pages/lecturer/lecturerSchedule',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师日程","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-lecturer-lecturerSchedule', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturerSchedule',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturerSchedule',
  windowTop:0
}
},
{
path: '/pages/lecturer/lecturerEval',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"评价","enablePullDownRefresh":false})
      },
      [
        createElement('pages-lecturer-lecturerEval', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturerEval',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturerEval',
  windowTop:44
}
},
{
path: '/pages/lecturer/lecturerFraction',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分数","enablePullDownRefresh":false})
      },
      [
        createElement('pages-lecturer-lecturerFraction', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-lecturer-lecturerFraction',
  isNVue:false,maxWidth:0,
  pagePath:'pages/lecturer/lecturerFraction',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseRecommend',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseRecommend', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseRecommend',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseRecommend',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseNavArea',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseNavArea', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseNavArea',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseNavArea',
  windowTop:44
}
},
{
path: '/pages/trainProgram/OmoNavArea',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训项目","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-OmoNavArea', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-OmoNavArea',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/OmoNavArea',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseRecommend',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseRecommend', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseRecommend',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseRecommend',
  windowTop:44
}
},
{
path: '/pages/app2022/CourseNavArea',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":false})
      },
      [
        createElement('pages-app2022-CourseNavArea', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app2022-CourseNavArea',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app2022/CourseNavArea',
  windowTop:44
}
},
{
path: '/pages/trainProgram/OmoNavArea',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"培训项目","enablePullDownRefresh":false})
      },
      [
        createElement('pages-trainProgram-OmoNavArea', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-trainProgram-OmoNavArea',
  isNVue:false,maxWidth:0,
  pagePath:'pages/trainProgram/OmoNavArea',
  windowTop:44
}
},
{
path: '/pages/ojt/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"导师带教","enablePullDownRefresh":false})
      },
      [
        createElement('pages-ojt-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-ojt-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/ojt/list',
  windowTop:44
}
},
{
path: '/pages/ojt/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"导师带教","enablePullDownRefresh":false})
      },
      [
        createElement('pages-ojt-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-ojt-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/ojt/detail',
  windowTop:44
}
},
{
path: '/pages/mentor/info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"导师信息","enablePullDownRefresh":false,"navigationStyle":"custom"})
      },
      [
        createElement('pages-mentor-info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-mentor-info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/mentor/info',
  windowTop:0
}
},
{
path: '/pages/mentor/task',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"带教任务详情","enablePullDownRefresh":false})
      },
      [
        createElement('pages-mentor-task', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-mentor-task',
  isNVue:false,maxWidth:0,
  pagePath:'pages/mentor/task',
  windowTop:44
}
},
{
path: '/pages/mentor/task-staff',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"完成明细","enablePullDownRefresh":false})
      },
      [
        createElement('pages-mentor-task-staff', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-mentor-task-staff',
  isNVue:false,maxWidth:0,
  pagePath:'pages/mentor/task-staff',
  windowTop:44
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
